.termsAndConditions {
  margin-top: 8rem;
  color: #000;
}

.termsAndConditions .sub-title {
  font-family: Poppins, sans-serif;
  font-size: 18px;
  color: #F16821;
  font-weight: 700;
}

.termsAndConditions .title {
  font-family: Poppins, sans-serif;
  font-size: 64px;
  font-weight: 700;
  color: #002755;
}

.termsAndConditions strong {
  font-weight: 600;
  font-size: 18px;
}

@media (min-width: 989px) {
  .termsAndConditions .section-head.style-1 h2,
  .termsAndConditions .section-head.style-1 .h2 {
    margin-bottom: 118rem !important;
  }

  .termsAndConditions .terms {
    margin-bottom: 40%;
  }
}

@media (min-width: 768px) and (max-width: 988px) {
  .termsAndConditions .title {
    margin-bottom: 35px;
    width: 50%;
  }

  .termsAndConditions .sub-title {
    font-family: Raleway, sans-serif;
    font-size: 18px;
    font-weight: 800;
  }

  .termsAndConditions {
    margin-top: 6rem;
  }

}

@media (max-width: 767px) {

  .termsAndConditions {
    margin-top: 6rem;
  }

  .termsAndConditions .sub-title {
    font-family: Raleway, sans-serif;
    font-size: 18px;
    font-weight: 800;
  }

  .termsAndConditions .title {
    font-family: Raleway, sans-serif;
    font-weight: 800;
    font-size: 32px;
    width: 70%;
    margin-bottom: 30px;
  }

  .termsAndConditions .container {
 padding-left: 0;
    padding-right: 0 ;
  }


}
