.footerWrapper .btn.btn-primary.instagramIconFooter {
  margin-left: 4px;
}

.text .m-b0 {
  margin-bottom: 0;
}

.site-footer {
  background-color: #02254f !important;
}

.footerOverlay.overlay-black-light:before,
.footerOverlay.overlay-black-middle:before,
.footerOverlay.overlay-black-dark:before {
  color: #02254f;
  background-color: #02254f;
}

.row.justify-content-center {
  color: white;
}

a.text-primary {
  margin: 5px;
}

.site-footer.style-3 .footer-logo-head .icon-bx-wraper {
  cursor: pointer;
}

.site-footer.style-3 .footer-bottom .copyright-text {
  color: white;
}

.site-footer.style-3 .footer-logo-head {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.flexEnd .row {
  justify-content: flex-end;
}

.footerWrapper .cat-item a {
  text-transform: none !important;
  font-family: Poppins, sans-serif;
  font-size: 15px;
  color: #fff;
  margin-bottom: 4px;
}

.footerWrapper .icon-content {
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #989292;
}

.footerWrapper .footer-link.d-inline-block {
  color: #fff;
}

.footerWrapper .title.m-b5 {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: #fff;
}

.footerWrapper .icon-content p {
  font-family: Poppins, sans-serif;
  font-size: 18px;
}

.footerWrapper .title {
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 24px;
  color: #fff;
}

.footerWrapper .widget.widget_about p {
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: #fff;
  width: 90%;
}

.footerWrapper .text.m-b20 {
  font-family: Poppins, sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
}

.footerWrapper .text.m-b0 {
  font-family: Poppins, sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
}

/********** Media queries ************/

@media (max-width: 767px) {
  .footerWrapper .title.m-b5 {
    font-size: 18px;
  }

  .footerWrapper .icon-content p {
    font-size: 16px;
  }

  .footerWrapper .widget.widget_about p {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 988px) {
  .footerWrapper .title {
    font-size: 23px;
  }
}
