.mainSection {
  height: unset;
  width: 100%;
}

.map-info {
  position: relative;
}

.mainSection .map-container {
  padding: 0;
}

/********** Media queries ************/

@media screen and (max-width: 991px) {
  .mainSection {
    margin-bottom: 60px;
  }
  .map-container {
    height: 85vh;
    position: relative;
    padding: 0;
  }
  .map-container > div > div > div {
    width: 100% !important;
  }

  .mainSection .img-fluid {
    margin-top: 257px;
  }
}
