.textWithImage .bg-img-wrapper {
  background: url('../../../assets/images/GasTank/gasTanks.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.textWithImage .rezervor-tank-col .bg-img-wrapper.rezervor-tank {
  background: url('../../../assets/images/GasTank/rsz_rezervor.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.textWithImage.counter-area-1 .media-full {
  border: none;
}

.textWithImage.bg-secondary {
  background-color: #002755 !important;
}

.textWithImage .section-head.style-4.text-white .title {
  font-family: Poppins, sans-serif;
  font-weight: 700;
  font-size: 48px;
  color: #fff;
}

.textWithImage .section-head.style-4.text-white p {
  font-family: Raleway, sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  width: 100%;
}

.textWithImage .list-square-check li {
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  display: flex;
  align-items: center;
}

.textWithImage .list-square-check li:last-of-type {
  margin: 0;
}

.textWithImage .list-square-check li:before {
  color: #ffffff;
  background-color: #f16821;
  display: grid;
  place-content: center;
}

.textWithImage .dz-title.counter {
  color: #000;
}

.textWithImage .icon-bx-wraper.style-4 .dz-title {
  font-family: Poppins, sans-serif;
  font-weight: 700;
  font-size: 35px;
  color: #f16821;
}

.textWithImage .icon-span {
  font-family: Poppins, sans-serif;
  font-weight: 700;
  font-size: 35px;
  color: #f16821;
  margin-left: 10px;
}

.textWithImage .icon-lg img {
  height: 60px;
}

.textWithImage .icon-bx-wraper.left.style-4.m-b30:hover img {
  filter: brightness(20);
}

.textWithImage .icon-bx-wraper.left.style-4.m-b30:hover .dz-title.counter {
  filter: brightness(20);
}

.textWithImage .icon-bx-wraper.left.style-4.m-b30:hover .icon-span {
  filter: brightness(20);
}

.textWithImage .icon-bx-wraper.style-4 .dz-title {
  transition: none;
}

/********** Media queries ************/

@media screen and (min-width: 991px) {
  .textWithImage .list-square-check li {
    height: 72px;
    padding: 0 0 0 97px;
    font-size: 21px;
  }
  .textWithImage .list-square-check li:first-of-type {
    margin: 0 0 20px;
  }
  .textWithImage .list-square-check li:before {
    width: 72px;
    height: 72px;
  }
}

@media (max-width: 767px) {
  .textWithImage .section-head.style-4.text-white .title {
    font-size: 30px;
  }
  .textWithImage .section-head.style-4.text-white p {
    font-size: 15px;
    padding-bottom: 30px;
  }

  .textWithImage .icon-span {
    font-size: 30px;
  }

  .textWithImage .icon-bx-wraper.style-4 .dz-title {
    font-size: 30px;
  }

  .textWithImage .icon-lg img {
    height: 40px;
  }

  .textWithImage .icon-bx-wraper.style-4 {
    padding: 25px 25px 25px 58px;
  }

  .textWithImage.counter-area-1 .media-full {
    height: 232px;
    transform: translate(0px, -6px);
  }

  .textWithImage .list-square-check li {
    height: 42px;
    padding: 0 0 0 69px;
  }
  .textWithImage .list-square-check li:first-of-type {
    margin: 0 0 31px;
  }
  .textWithImage .list-square-check li:before {
    width: 42px;
    height: 42px;
    min-height: unset;
  }

  .textWithImage .row > * {
    padding-left: 0;
    padding-right: 0;
  }
  .textWithImage .bg-img-wrapper {
    background-position: center;
  }

  .textWithImage .biggerHeight {
    padding-bottom: 25px;
  }

  .textWithImage .col-md-12 {
    margin-left: 0;
  }
}

@media (min-width: 768px) and (max-width: 988px) {
  .textWithImage .section-head.style-4.text-white p {
    width: 80%;
  }

  .textWithImage .bg-img-wrapper {
    background: url('../../../assets/images/GasTank/gasTankCut.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    transform: translate(-58px, 0);
  }

  .textWithImage .rezervor-tank-col .bg-img-wrapper.rezervor-tank {
    background: url('../../../assets/images/GasTank/rsz_rezervor.png');
    background-repeat: no-repeat;
    background-size: cover;
    transform: translate(-58px, 0);
  }

  .textWithImage .section-head.style-4.text-white .title {
    font-size: 36px;
  }

  .textWithImage
    .col-lg-1.col-md-1.content-inner.counter-col.align-self-center {
    padding-left: 0;
    padding-right: 0;
  }

  .textWithImage .icon-bx-wraper.style-4 .dz-title {
    font-size: 30px;
  }

  .textWithImage .icon-span {
    font-size: 30px;
  }

  .textWithImage .icon-lg img {
    height: 55px;
  }

  .textWithImage .row {
    --bs-gutter-x: 0;
  }
  .textWithImage .icon-bx-wraper.style-4 {
    width: 77%;
    height: 86%;
    transform: translate(-48px, 0px);
  }

  .textWithImage .col-md-12 {
    margin-left: 0;
  }

  .textWithImage .list-square-check li {
    height: 60px;
    padding: 0 0 0 76px;
  }
  .textWithImage .list-square-check li:first-of-type {
    margin: 0 0 8px;
  }
  .textWithImage .list-square-check li:before {
    width: 60px;
    height: 60px;
  }
}
