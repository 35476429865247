.offersSectionMedia.dz-media img,
.offersSectionMedia.dz-post-media img {
  width: auto;
}

.subtitleLayout {
  display: flex;
  justify-content: center;
}

.titleMargin {
  margin-bottom: 3rem;
}

.borderRound.list-square-check li:before {
  border-radius: 50% !important;
  background-color: #707070;
  color: #fff;
}

.offers-section .list-square-check li:hover:before {
  background-color: #f16821;
}

.offersSectionSubtitle.section-head.style-1 h2,
.section-head.style-1 .h2 {
  font-family: Poppins, sans-serif;
  font-weight: 800;
  font-size: 40px;
  color: #333;
}

.icon-bx-wraper.style-9 .icon-bx {
  background-color: grey;
}

.icon-bx-wraper.style-9 .list-num {
  background-color: grey;
  border: 1px solid white;
}

.icon-bx-wraper.style-9.active .list-num,
.icon-bx-wraper.style-9:hover .list-num {
  border: 1px solid #f16821;
  color: #000;
}

.offers-section .year-exp {
  left: 0;
  bottom: 0;
  top: auto;
  right: auto;
  animation: none 0;
  border-bottom: none;
  padding: 0 5rem 0 0;
  background-color: #f16922;
  height: 68px;
}

.offers-section .year-exp .text {
  font-size: 11px;
  color: #fff;
  font-family: Poppins, sans-serif;
  font-weight: 700;
}

.offers-section .year-exp .year {
  font-size: 27px;
  border: 3px solid #fff;
  padding: 15px;
  margin: 2px;
  color: #fff !important;
  font-family: Poppins, sans-serif;
  font-weight: 600;
}

.offers-section .orangeBehindBox {
  right: -30px !important;
  top: -15px !important;
  bottom: auto;
  left: auto;
  border: none;
  background-color: var(--primary) !important;
  width: 296px;
  height: 89px;
  z-index: -1;
}

.offers-section .about-media {
  position: unset;
}

.offers-section .overlap-container {
  position: relative;
}

.offers-section
  .col-xl-4.col-lg-4.m-lg-b30.aos-item.aos-init.aos-animate.p-0.overlap-container {
  z-index: 0 !important;
}

.offers-section .icon-cell img {
  height: auto;
}

.offers-section .text-primary.sub-title {
  font-family: Raleway, sans-serif !important;
  font-weight: 800;
  font-size: 18px;
}

.offers-section .col-xl-8.col-lg-8 {
  padding-right: 0;
  padding-left: 0;
}

.offers-section .text-primary.marginNone {
  font-family: Raleway, sans-serif;
  font-weight: 700;
  font-size: 18px;
}

.offers-section .icon-content p {
  font-family: Raleway, sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #333;
}

.offers-section .list-square-check.grid-2.borderRound li {
  font-family: Raleway, sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #000;
}

.offers-section .icon-bx-wraper.left.m-b50.style-9.box-hover.offersSectionHover,
.offers-section .icon-bx-wraper.left.m-b50.style-9.box-hover {
  margin-bottom: 30px;
}

/********** Media queries ************/

@media (min-width: 1200px) {
  .offers-section .description-items {
    padding: 0 45px 0 88px;
  }

  .aos-item.mx-auto.d-block {
    transform: translate(24rem, 0.7rem) !important;
  }

  .offersSectionSubtitle.row.align-items-center.section-head.style-1 {
    justify-content: flex-end;
    margin-right: 17rem;
    margin-bottom: 3.3rem;
  }
}

@media (min-width: 992px) {
  .aos-item.mx-auto.d-block {
    transform: translate(15rem, 0.7rem) !important;
  }

  .offers-section
    .offersSectionSubtitle.row.align-items-center.section-head.style-1.mobileTitleAndText {
    display: none;
  }
}

@media (max-width: 991px) {
  .offers-section
    .offersSectionSubtitle.row.align-items-center.section-head.style-1.desktopTitleAndText {
    display: none;
  }
}
@media (max-width: 374px) {
  .offers-section .orangeBehindBox {
    left: 45vw;
  }

  .offers-section .year-exp.aos-item {
    bottom: 88px;
  }
}

@media (min-width: 375px) and (max-width: 667px) {
  .offers-section .col-lg-12.align-self-center.aos-item.description-items {
    padding-left: 0;
  }

  .offers-section .orangeBehindBox {
    left: 44vw;
  }

  .offers-section
    .offersSectionSubtitle.row.align-items-center.section-head.style-1.mobileTitleAndText {
    margin-top: 30px;
  }

  .offers-section .offersSectionSubtitle.section-head.style-1 h2,
  .section-head.style-1 .h2 {
    font-size: 32px;
  }
}

@media only screen and (max-width: 991px) {
  .year-exp.aos-item {
    display: inline-flex;
    bottom: 109px;
    width: 56%;
  }
}

@media screen and (min-width: 768px) and (max-width: 988px) {
  .offers-section .overlap-container {
    margin-bottom: 40px;
  }

  .offers-section .dz-media {
    margin-bottom: 60px;
  }

  .offers-section .dz-media img {
    padding-left: 0;
    padding-right: 85px;
  }

  .offers-section .dz-media .year-exp.aos-item {
    width: 357px;
    height: 91px;
    bottom: 143px;
  }

  .offers-section .dz-media .year-exp.aos-item.orangeBehindBox {
    width: 405px;
    height: 121px;
    top: -25px !important;
    right: 40px !important;
  }

  .offers-section .dz-media .year-exp.aos-item h2 {
    display: grid;
    width: 82px;
    height: 82px;
    font-size: 32px;
    padding: 0;
    place-content: center;
  }

  .offers-section .dz-media .year-exp.aos-item h4 {
    font-size: 15px;
  }

  .offers-section .list-square-check.grid-2.borderRound li {
    margin-bottom: 30px;
    padding-left: 88px;
  }

  .offers-section .list-square-check.grid-2.borderRound li:before {
    width: 42px;
    height: 42px;
  }

  .offers-section .inner-content {
    margin-bottom: 60px;
  }

  .offers-section .inner-content:last-child {
    margin-bottom: 0;
  }

  .offers-section .col-lg-6.aos-item {
    padding-left: 9px;
  }

  .offers-section .description-items {
    padding: 0 72px 0 3px;
  }

  .offers-section .list-square-check.grid-2.borderRound {
    padding-left: 18px;
    display: flex;
    justify-content: space-between;
  }

  .offers-section .list-square-check.grid-2.borderRound li {
    width: 45%;
  }

  .offersSectionSubtitle.section-head.style-1 h2 {
    font-size: 36px;
  }
}
