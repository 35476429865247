#stations .tetris {
  padding: 70px 0 0;
}

#stations .about-bx6 .dz-media .img2:after {
  width: 80%;
  top: 20px;
  right: 50%;
  border-color: #e4e4e4;
}

#stations .stations-tittle {
  font-family: Poppins, sans-serif;
}

@media screen and (min-width: 1439px) {
  #stations .tetris-big-img {
    width: 370px;
    height: 534px;
    max-width: unset;
  }
}

@media screen and (min-width: 991px) {
  #stations .backroungImage {
    height: 33rem;
  }
  #stations .stations-tittle {
    font-size: 72px;
  }

  #stations .dz-bnr-inr-entry {
    padding-top: 132px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  #stations .backroungImage {
    height: 34.875rem;
  }
  #stations .container {
    max-width: unset;
    padding: 0 27px 0 8px;
  }

  #stations .content-inner {
    padding-top: 58px;
  }

  #stations .tetris {
    margin-bottom: 115px;
  }

  #stations .stations-tittle {
    font-size: 42px;
  }

  #stations .dz-bnr-inr-entry {
    padding-top: 80px;
  }
}

@media screen and (max-width: 767px) {
  #stations .backroungImage {
    height: 21.875rem;
  }
  #stations .stations-tittle {
    font-family: Poppins, sans-serif;
    font-size: 36px;
  }
  #stations .dz-bnr-inr-entry {
    padding-top: 80px;
  }

  #stations .container {
    max-width: unset;
    padding: 0 3px;
  }
  #stations .about-bx6 .dz-media .img1 {
    padding: 16px;
  }

  #stations .tetris {
    margin: 0 0 72px;
  }

  #stations .tetris-big-img {
    padding: 13px;
  }
}

@media screen and (max-width: 575px) {
  #stations .about-bx6 .dz-media .img2:after {
    transform: translateX(25%);
  }
}
