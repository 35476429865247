.marginNone.text-primary {
  margin-left: 0;
}

.simpleInfoBoxTitle {
  color: #002755;
}

.simpleInfoBox .col-lg-6.aos-item p {
  font-family: Raleway, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #333;
}

.simpleInfoBox .text-primary.marginNone {
  font-family: Raleway, sans-serif;
  font-weight: 800;
  font-size: 16px;
}

/********** Media queries ************/

@media (min-width: 1200px) {
  .simpleInfoBox.content-inner-2 {
    padding-top: 57px;
    padding-bottom: 20px;
  }

  .simpleInfoBox .col-lg-6.aos-item p {
    width: 119%;
  }
}

@media (min-width: 992px) {
  .simpleInfoBoxTitle {
    margin-bottom: 10rem;
  }
}

@media (max-width: 374px) {
  .simpleInfoBox .title.simpleInfoBoxTitle {
    padding-bottom: 20px;
  }
}

@media (min-width: 375px) and (max-width: 667px) {
  .simpleInfoBox.content-inner-2 {
    padding-top: 32px;
    padding-bottom: 20px;
  }

  .simpleInfoBox .title.simpleInfoBoxTitle {
    margin-bottom: 24px;
  }
}

@media screen and (min-width: 768px) and (max-width: 988px) {
  .simpleInfoBox h5.sub-title {
    font-size: 18px;
  }

  .simpleInfoBox .title.simpleInfoBoxTitle {
    margin-bottom: 30px;
  }

  .simpleInfoBox .col-lg-6.aos-item p {
    font-size: 15px;
  }
}
