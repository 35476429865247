#fuel {
  overflow: hidden;
}

#fuel .town {
  font-family: Poppins, sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: #000;
  margin-bottom: 0;
}

#fuel .townPhone {
  font-family: Poppins, sans-serif;
  font-weight: 300;
  font-size: 20px;
  color: #000;
}

#fuel .contact-link-btn span {
  font-family: Raleway, sans-serif;
  font-weight: 600;
  color: #fff;
}

#fuel .icon-bx-wraper {
  margin-bottom: 20px;
}

#fuel .fuelText {
  font-family: Poppins, sans-serif;
  font-weight: 700;
  font-size: 72px;
  color: #fff;
  margin-top: 9rem;
}

#fuel .container.cst {
  position: relative;
}
#fuel .container.cst .row,
#fuel .page-content,
#fuel .logos-container {
  position: relative;
  z-index: 1;
}

#fuel .container.cst .cst-normal .title.same-title,
#fuel .container.cst .cst-reverse .title.same-title {
  max-width: 243px;
  font-size: 40px;
  font-weight: bold;
}

#fuel .container.cst .cst-normal .title.same-title {
  margin-bottom: 45px;
}

#fuel .container.cst .cst-reverse .title.same-title {
  margin-bottom: 72px;
}

#fuel .container.cst p {
  color: #000000;
}
#fuel .container.cst .icon-bx-wraper {
  display: flex;
  align-items: center;
}
#fuel .container.cst .icon-bx-wraper i {
  display: grid;
  place-content: center;
  border: 3px solid #f16821;
  color: #f16821;
  font-size: 32px;
}

#fuel .container.cst .icon-bx-wraper p {
  font-family: Poppins, sans-serif;
  font-weight: 300;
  color: #000;
}

#fuel .container.cst .cst-reverse .contact-link-btn {
  cursor: pointer;
  background-color: #f16821;
  height: 60px;
  display: grid;
  place-content: center;

  color: #ffffff;
}

/*MEDIA*/

@media screen and (min-width: 992px) {
  #fuel .backroungImage {
    height: 33rem;
  }

  #fuel .logos-container {
    margin: 63px 0 49px;
  }

  #fuel .container.cst .cst-normal {
    padding-top: 113px;
  }

  #fuel .container.cst .cst-reverse {
    padding-top: 205px;
  }

  #fuel .container.cst .cst-normal .p-wrapper {
    margin-bottom: 70px;
    max-width: 433px;
  }

  #fuel .container.cst .cst-reverse p {
    margin-bottom: 162px;
    max-width: 395px;
  }

  #fuel .container.cst .cst-reverse .contact-link-btn {
    max-width: 175px;
    font-size: 18px;
  }

  #fuel .container.cst .icon-bx-wraper i {
    width: 63px;
    height: 63px;
    margin-right: 13px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  #fuel .backroungImage {
    height: 34.875rem;
  }
  #fuel .logos-container {
    margin: 30px 0 33px;
  }

  #fuel .fuelText {
    font-size: 42px;
    margin-top: 4rem;
  }

  #fuel .container.cst .cst-normal {
    padding-top: 153px;
  }

  #fuel .container.cst .cst-reverse {
    padding-top: 220px;
  }

  #fuel .container.cst .cst-normal .title.same-title,
  #fuel .container.cst .cst-reverse .title.same-title {
    max-width: 243px;
  }

  #fuel .container.cst .icon-bx-wraper p {
    font-size: 20px;
  }
  #fuel .container.cst .cst-normal .p-wrapper {
    margin-bottom: 11px;
    max-width: 286px;
  }

  #fuel .container.cst .cst-reverse p {
    margin-bottom: 64px;
    max-width: 270px;
  }

  #fuel .container.cst .cst-reverse .contact-link-btn {
    max-width: 153px;
    font-size: 15px;
  }

  #fuel .container.cst .icon-bx-wraper i {
    width: 63px;
    height: 63px;
    margin-right: 15px;
  }

  #fuel .town {
    font-weight: 500;
  }

}

@media screen and (max-width: 767px) {
  #fuel .backroungImage {
    height: 21.875rem;
  }
  #fuel .fuelText {
    font-size: 36px;
    margin-bottom: 80px;
  }

  #fuel .container.cst .cst-normal,
  #fuel .container.cst .cst-reverse {
    padding-top: 35px;
  }

  #fuel .container.cst .cst-normal {
    padding-bottom: 86px;
  }

  #fuel .container.cst .cst-reverse {
    padding-bottom: 64px;
  }

  #fuel .logos-container {
    margin: 20px 0 5px;
  }

  #fuel .logos-container img {
    width: 230px;
    height: 69px;
  }

  #fuel .container.cst .cst-normal .title.same-title,
  #fuel .container.cst .cst-reverse .title.same-title {
    margin-bottom: 30px;
    font-size: 32px;
    max-width: 194px;
  }

  #fuel .container.cst .cst-normal .p-wrapper {
    margin-bottom: 30px;
    max-width: 100%;
  }

  #fuel .container.cst .p-wrapper p {
    font-size: 16px;
  }

  #fuel .container.cst .icon-bx-wraper p {
    font-size: 18px;
  }

  #fuel .container.cst .icon-bx-wraper {
    margin: 0;
  }

  #fuel .container.cst .icon-bx-wraper i {
    width: 50px;
    height: 50px;
    margin-right: 16px;
  }

  #fuel .container.cst .cst-reverse .contact-link-btn {
    max-width: 175px;
    font-size: 15px;
  }

  #fuel .town {
    font-weight: 500;
    font-size: 18px;
  }

  #fuel .container.cst .icon-bx-wraper {
    margin-bottom: 10px;
  }
}
