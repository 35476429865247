.simpleTextBanner .icon-bx-wraper {
  display: flex;
  align-items: center;
  margin-top: 36px;
}

.simpleTextBanner .icon-bx-wraper i {
  width: 63px;
  height: 63px;
  margin-right: 13px;
  display: grid;
  place-content: center;
  border: 3px solid #f16821;
  color: #f16821;
  font-size: 32px;
}

.simpleTextBanner .town {
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #000;
  margin-bottom: 0;
}

.simpleTextBanner .townPhone {
  font-family: Poppins, sans-serif;
  font-weight: 300;
  font-size: 18px;
  color: #000;
}

.simpleTextBanner .transform {
  transform: translate(0, 16px);
}

.simpleTextBanner {
  position: relative;
  padding-top: 130px;
  padding-bottom: 172px;
  z-index: 0;
  overflow: hidden;
}

.simpleTextBanner .simpleText {
  font-family: Raleway, sans-serif;
  font-weight: 400;
  font-size: 16px;
  max-width: 940px;
  width: 100%;
  margin: auto;
  color: #000;
}

.simpleTextBanner .leftWheat {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -2;
  height: 133%;
}

.simpleTextBanner p {
  margin-block-start: 0;
  margin-block-end: 0;
}

.simpleTextBanner .landWithSeeds {
  width: 100%;
  object-fit: cover;
  height: 100%;
  max-width: 1186px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: -2;
  top: 0;
}

.simpleTextBanner .grain {
  position: absolute;
  bottom: 40%;
  transform: translate(5rem, 16rem);
  height: 100%;
  right: 0;
  width: 38rem;
  z-index: -1;
  mix-blend-mode: multiply;
}

/********** Media queries ************/

@media (min-width: 989px) {
  .simpleTextBanner .landsTablet {
    display: none;
  }

  .simpleTextBanner .landsMobile {
    display: none;
  }
}

@media (max-width: 767px) {
  .simpleTextBanner .town {
    font-size: 15px;
  }

  .simpleTextBanner .townPhone {
    font-size: 15px;
  }

  .simpleTextBanner .simpleText {
    font-family: Raleway, sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: #000;
  }

  .simpleTextBanner .landsTablet {
    display: none;
  }

  .simpleTextBanner {
    padding: 60px 23px 50px 20px;
  }

  .simpleTextBanner .landWithSeeds {
    display: none;
  }

  .simpleTextBanner .landsMobile {
    position: absolute;
    z-index: -1;
  }

  .simpleTextBanner .grain,
  .simpleTextBanner .leftWheat {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 988px) {
  .simpleTextBanner .grain,
  .simpleTextBanner .leftWheat,
  .simpleTextBanner .landWithSeeds {
    display: none;
  }

  .simpleTextBanner .landsTablet {
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 100%;
    top: 12px;
  }

  .simpleTextBanner {
    padding: 0;
  }

  .simpleTextBanner .simpleText {
    padding: 80px 42px;
  }

  .simpleTextBanner .landsMobile {
    display: none;
  }
}
