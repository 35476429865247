.clients-section-1 {
  margin-bottom: 61px;
}

/********** Media queries ************/

@media (max-width: 767px) {
  .clients-section-1 {
    margin-bottom: 46px;
  }
}
