.bannerWithColumns.counter-area1 .counter-inner .icon-bx-wraper {
  flex-direction: column;
}

.bannerWithColumns .icon-bx-wraper.style-12:after {
  content: none;
}

.bannerWithColumns .icon-bx-wraper.style-12 .title {
  padding-left: 0;
}

.bannerWithColumns.counter-area1 .counter-inner {
  margin-top: 5rem;
  padding: 0;
}

.bannerWithColumns .counter-inner.bg-primary {
  box-shadow: none !important;
}

.bannerWithColumns.counter-area1.half-bg {
  background-image: linear-gradient(to right, #f16821, #f16a24, #f18221);
  max-width: 1199px;
  margin: auto;
}

.bannerWithColumns.counter-inner.bg-primary {
  background-color: transparent !important;
}

.bannerWithColumns .icon-bx-wraper.style-12.m-b30 {
  margin-bottom: 3px;
  margin-top: 20px;
}

.bannerWithColumns .icon-bx-wraper.style-12.m-b30 .counter {
  font-family: Poppins, sans-serif;
  font-weight: 700;
  font-size: 68px;
}

.bannerWithColumns .counterWrapper {
  display: flex;
}

.bannerWithColumns .counterText {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 68px;
  color: #fff;
  line-height: 1;
}

.bannerWithColumns .title {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 18px;
}

.bannerWithColumns .align {
  display: contents;
}

/********** Media queries ************/

@media (min-width: 988px) {
  .bannerWithColumns .icon-bx-wraper.style-12.m-b30 {
    align-items: start;
    margin-left: 30%;
  }
}

@media (max-width: 767px) {
  .bannerWithColumns .icon-bx-wraper.style-12.m-b30 {
    align-items: start;
    margin-left: 30%;
  }

  .bannerWithColumns .title {
    font-weight: 600 !important;
  }

  .bannerWithColumns .container.bannerColor {
    padding-bottom: 30px;
    padding-top: 30px;
  }

  .bannerWithColumns .row > * {
    padding-left: 0;
  }

  .bannerWithColumns .counterText {
    font-size: 50px;
  }
  .bannerWithColumns .icon-bx-wraper.style-12.m-b30 .counter {
    font-size: 50px;
  }

  .bannerWithColumns.counter-area1.half-bg {
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media (min-width: 375px) and (max-width: 667px) {
  .bannerWithColumns.counter-area1 .counter-inner {
    margin-top: 1rem;
  }

  .bannerWithColumns.counter-area1.half-bg {
    background-image: linear-gradient(to left, #f16821, #f16a24, #f18221);
  }
}

@media screen and (min-width: 768px) and (max-width: 988px) {
  .bannerWithColumns.counter-area1.half-bg {
    margin: 0 40px;
  }

  .bannerWithColumns .counter-inner.bg-primary.bannerWithColumns {
    margin-top: 0;
  }

  .bannerWithColumns .container.bannerColor {
    padding: 0 87px 0 128px;
  }

  .bannerWithColumns .icon-bx-wraper.style-12.m-b30 {
    align-items: start;
  }

  .bannerWithColumns .row {
    display: flex;
    justify-content: space-between;
  }

  .bannerWithColumns .col-lg-3.col-sm-6.aos-item {
    width: 40%;
  }

  .bannerWithColumns .icon-bx-wraper.style-12.m-b30 .counter,
  .bannerWithColumns .icon-bx-wraper.style-12.m-b30 .counterText {
    font-size: 50px;
  }
}
