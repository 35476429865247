.orange-banner {
  position: absolute;
  right: 50%;
  transform: translateX(50%);
}

.orange-banner .row {
  justify-content: center;
  align-items: center;
}

.orange-banner .counter.number-wr {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 50px;
  color: #fff;
}

.orange-banner .container {
  padding: 0 !important;
}

.orange-banner .icon-bx-wraper.style-12:after {
  content: none;
  display: none;
}

.orange-banner .nrWrapper {
  flex-direction: column;
  width: 100px;
}

.orange-banner .icon-gz {
  margin-bottom: 10px;
}

.orange-banner .counter.number-wr {
  padding-left: 20px;
}

.orange-banner .icon-bx-wraper.style-12 .title {
  font-family: Poppins, sans-serif;
}

/********** Media queries ************/

@media screen and (min-width: 992px) {
  .orange-banner {
    max-width: 1160px;
    min-width: 945px;
    bottom: -94px;
  }
  .orange-banner .counter-inner {
    padding: 58px 0 58px 0;

    background: transparent
      linear-gradient(90deg, #f16821 0%, #f16a24 47%, #f18221 100%) 0 0
      no-repeat padding-box;
  }

  .orange-banner .icon-bx-wraper.style-12 .title {
    font-size: 15px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .orange-banner {
    max-width: 770px;
    width: 100%;
    bottom: -191px;
    padding: 45px 40px 48px;
    transform: translate(50%, -22%);
  }

  .orange-banner .counter-inner {
    padding: 45px 40px 48px;
    background: transparent
      linear-gradient(90deg, #f16821 0%, #f16a24 47%, #f18221 100%) 0 0
      no-repeat padding-box;
  }

  .orange-banner .icon-bx-wraper.style-12 .title {
    font-size: 18px;
  }
}

@media screen and (max-width: 767px) {
  .orange-banner {
    transform: translate(50%, -22%);
  }
  .orange-banner .counter-inner {
    width: 344px;
    margin: 0 auto;
  }

  .orange-banner .counter-inner {
    padding: 54px 0 0;
    background: transparent
      linear-gradient(260deg, #f16821 0%, #f16a24 47%, #f18221 100%) 0 0;
  }

  .orange-banner .icon-bx-wraper {
    margin-bottom: 70px;
  }
}
