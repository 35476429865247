.simpleBanner.dz-bnr-inr.dz-bnr-inr-sm {
  margin-top: 0;
}

.simpleBanner {
  height: 46rem !important;
}

.container.titleAlign {
  display: flex;
  margin-top: 7rem;
  margin-left: 2.5rem;
  align-items: flex-end;
}

.simpleBanner .text-white {
  font-weight: 800;
}

/********** Media queries ************/

@media (max-width: 767px) {
  .simpleBanner {
    height: 320px !important;
  }

  .simpleBanner .text-white {
    text-align: left;
    max-width: 150px;
  }
}

@media (min-width: 768px) and (max-width: 988px) {
  .simpleBanner {
    height: 30rem !important;
  }
  .simpleBanner.dz-bnr-inr.dz-bnr-inr-sm h1,
  .simpleBanner.dz-bnr-inr.dz-bnr-inr-sm .h1 {
    width: 50px;
  }
}
