.fuel-efix:after {
  content: '';
  position: absolute;
  background: #efefef;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 86%;
  z-index: -1;
}

.fuel-efix .title {
  color: #002755;
}

.fuel-efix .sub-title {
  font-weight: 800;
  margin: 0;
}

@media screen and (min-width: 992px) {
  .fuel-efix {
    margin: 105px 0;
    padding: 33px 30px 0;
  }

  .fuel-efix .title {
    font-size: 64px;
    color: #002755;
    margin: 4px 0 0 0;
  }

  .fuel-efix .section-head {
    margin-bottom: 31px;
  }
}

@media screen and (min-width: 767px) and (max-width: 991px) {
  .fuel-efix {
    margin: 30px 0;
    padding: 21px 7px 0;
  }

  .fuel-efix .title {
    font-size: 36px;
    margin: 0;
  }
}

@media screen and (max-width: 767px) {
  .fuel-efix {
    padding-top: 49px;
  }

  .fuel-efix .section-head {
    margin-bottom: 18px;
  }

  .fuel-efix {
    margin-bottom: 35px;
  }
}
