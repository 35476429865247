.extra-nav span a {
  color: #fff;
}
.is-fixed .main-bar .extra-nav span a {
  color: #002755;
}

.site-header.header-overlay {
  background: rgba(0, 39, 85, 0.8);
}

.top-bar .dz-topbar-inner {
  margin-right: 40px;
}

.header-transparent .is-fixed .nav > li > a {
  color: #163655;
}

.site-header.style-2.header-transparent .is-fixed .extra-nav .phone-call {
  color: #163655;
}

.site-header .extra-nav .phone-call {
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: 22px;
}

.header-nav .nav > li .mega-menu li a,
.header-nav .nav > li .sub-menu li a {
  color: #163655;
}

.headerWrapper .dz-topbar-left {
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: #fff;
}

/********** Media queries ************/

@media (min-width: 992px) {
  .site-header.style-2 .header-nav .nav > li > a {
    padding: 36px 25px;
  }
}

@media (max-width: 992px) {
  .header-nav .dz-social-icon {
    text-align: left;
    padding-left: 20px;
  }
}
