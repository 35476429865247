.container.cst .img-fluid.rounded-lg {
  margin-top: 15px;
  margin-bottom: 15px;
}
.container.cst .middle-area,
.container.cst .last-area,
.comboOrange > picture:last-of-type {
  position: relative;
  display: block;
}

.container.cst .middle-area picture:before,
.container.cst .last-area picture:before,
.comboOrange > picture:last-of-type:before {
  content: '';
  position: absolute;
  top: 15px;
  height: calc(100% - 30px);
  /* background: rgba(241, 104, 33, 0.34); */
  width: 100%;
}

.container.cst .last-area picture:before {
  width: 100%;
}

.container.cst .cst:first-of-type {
  padding-left: 0;
}

.container.cst .cst:last-of-type {
  padding-right: 0;
}

.container.cst .img-fluid.rounded-lg.first {
  width: 670px;
}

.container.cst .img-fluid.rounded-lg.middle {
  width: 320px;
  height: 191px;
}

.container.cst .img-fluid.rounded-lg.last {
  width: 670px;
  height: 281px;
}

.container.cst .col-lg-5 {
  max-width: 468px;
  padding-top: 15px;
  background-color: rgba(255, 255, 255, 0.64);
}

/*FLOWER ANIMATION*/

.container.cst .circle-bg-one,
.container.cst .circle-bg-two,
.container.cst .circle-bg-three {
  position: absolute;
}

.container.cst .circle-bg-one {
  animation: flower1 8s linear infinite;
}

@keyframes flower1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.container.cst .circle-bg-one {
  top: -227px;
  left: -346px;
  height: 652px;
  width: 652px;
}

.container.cst .circle-bg-two {
  top: 488px;
  right: -327px;
  width: 562px;
  height: 562px;
  position: absolute;
}

.container.cst .circle-bg-three {
  top: -130px;
  left: -235px;
  width: 487px;
  height: 487px;
}

/*MEDIA*/

@media screen and (min-width: 1230px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1230px;
  }
}

@media screen and (min-width: 991px) {
  .container.cst .middle-area picture:before {
    width: calc(100% - 15px);
  }
}

@media screen and (max-width: 991px) {
  .container.cst .cst {
    padding: 0;
  }
}

@media screen and (min-width: 768px) {
  .container.cst .reverseColumn {
    order: 1;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .container.cst .middle-area picture:before {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {

  .container.cst .img-fluid.rounded-lg.middle {
    width: 100%;
    height: auto;
  }

  .container.cst {
    padding: 0;
  }

  .container.cst .col-lg-5 {
    margin-bottom: 0;
  }

  .container.cst .cst:first-of-type {
    display: grid;
  }

  .container.cst .img-fluid {
    object-fit: cover;
  }

  .container.cst .cst:last-of-type {
    display: grid;
  }

  .container.cst .middle-area picture:before {
    width: 100%;
  }
}
