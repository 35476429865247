.mixed-stations .title {
  color: #002755;
  font-family: Poppins, sans-serif;
  font-weight: bold;
  margin-bottom: 35px;
}

.mixed-stations-section .tetris-big-img {
  padding: 20px;
  max-height: 533px;
}

.mixed-stations-section .stations-btn {
  border: 2px solid #f16821;
  outline: none;
  background-color: #ffffff;

  font-family: Poppins, sans-serif;
  color: #f16821;
  font-weight: 800;
}

.mixed-stations-section .stations-btn.grey {
  border-color: #aaa;
  color: #aaa;
}

.mixed-stations .wrapper {
  margin: 44px 0;
}

.mixed-stations .wrapper p {
  font-size: 15px;
  color: #000000;
  text-align: left;
}

.mixed-stations .wrapper p:last-of-type {
  margin: 0;
}

.mixed-stations .list-square-check li:before {
  background-color: #f16821;
  color: #ffffff;
  width: 41px;
  height: 41px;
  display: grid;
  place-content: center;
}

.mixed-stations .list-square-check.grid-2 li {
  display: flex;
  align-items: center;
  font-family: Raleway, sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #000;
  padding: 0 0 0 81px;
}

.mixed-stations .list-square-check li.disable:before {
  background-color: #e8e7e7;
  content: '';
}

.mixed-stations .list-square-check li.disable {
  color: #e8e7e7;
}

@media screen and (min-width: 1440px) {
  .mixed-stations-section {
    overflow: hidden;
  }
  .mixed-stations-section .container {
    position: relative;
  }

  .mixed-stations-section .flower {
    position: absolute;
  }

  .mixed-stations-section .flower.one {
    width: 260px;
    height: 260px;
    top: 250px;
    left: -340px;
  }

  .mixed-stations-section .flower.two {
    width: 375px;
    height: 375px;
    top: 25px;
    right: -300px;
  }
}

@media screen and (max-width: 1439px) {
  .mixed-stations-section .flower {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .mixed-stations .title {
    font-size: 48px;
  }

  .mixed-stations {
    padding: 9px 0 18px 35px;
  }

  .mixed-stations .stations-btn:last-of-type {
    margin-left: 16px;
  }

  .mixed-stations .list-square-check.grid-2 li {
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 991px) {
  .mixed-stations .title {
    text-align: center;
    font-size: 32px;
  }

  .mixed-stations .btn-area {
    justify-content: space-evenly;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .mixed-stations .list-square-check {
    margin: 0 50px;
  }

  .mixed-stations .list-square-check.grid-2 li {
    margin-bottom: 16px;
  }
}

@media screen and (min-width: 767px) {
  .mixed-stations .stations-btn {
    width: 217px;
    height: 64px;
    font-size: 15px;
  }
}

@media screen and (max-width: 767px) {
  .mixed-stations {
    padding: 0 12px;
  }

  .mixed-stations .stations-btn {
    width: 153px;
    height: 46px;
    font-size: 14px;
  }

  .mixed-stations .list-square-check.grid-2 li {
    margin-bottom: 10px;
  }
}
