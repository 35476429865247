.textWithTitle .transform {
  transform: translate(0, -16px);
}

.textWithTitle .title {
  font-family: Poppins, sans-serif;
  font-weight: 700;
  font-size: 64px;
  color: #002755;
}

.textWithTitle .section-head p {
  font-family: Raleway, sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #333;
}

.textWithTitle .section-head.style-1 .sub-title {
  font-weight: 800;
}

/********** Media queries ************/

@media (min-width: 989px) {
  .textWithTitle.content-inner-2.service-area-1 {
    padding-bottom: 108px;
  }
}

@media (min-width: 375px) and (max-width: 767px) {
  .textWithTitle .section-head.style-1 h2,
  .textWithTitle .section-head.style-1 .h2 {
    font-family: Raleway, sans-serif;
    font-size: 30px;
    font-weight: 800;
    padding-bottom: 30px;
  }

  .textWithTitle .row > * {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 768px) and (max-width: 988px) {
  .textWithTitle .section-head.style-1 .sub-title {
    font-size: 18px;
    font-weight: 800;
    font-family: Raleway, sans-serif;
    margin-bottom: 5px;
  }

  .textWithTitle.content-inner-2 {
    padding-top: 60px;
  }

  .textWithTitle .section-head.style-1 h2,
  .section-head.style-1 .h2 {
    margin-bottom: 16px;
  }
}
