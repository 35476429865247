.mainSection .dz-form.bg-primary.style-1.information {
  position: absolute;
  z-index: 1;
  background: transparent
    linear-gradient(270deg, #f16821 0%, #f16a24 47%, #f18221 100%) 0 0 no-repeat
    padding-box;
}

.mainSection .title.info-title {
  color: #ffffff;
  font-family: Poppins, sans-serif;
  font-weight: bold;
}

.mainSection .icon-info-area {
  align-items: center;
}

.mainSection .icon-info-area picture {
  width: 24px;
  height: 24px;
  background-color: #ffffff;
  display: grid;
  place-content: center;
}

.mainSection .info-icon-map {
  width: 12px;
  height: 16px;
}

.mainSection .info-icon-phone {
  width: 14px;
  height: 14px;
}

.mainSection .mapP {
  margin: 0;
  padding-left: 17px;
  color: #ffffff;
  font-family: Poppins, Light, sans-serif;
  font-weight: 300;
}

.mainSection .icon-info-area:last-of-type {
  margin: 0;
}

.mainSection .form-label.one {
  margin: 0;
}

.mainSection .icon-info-area {
  margin-bottom: 24px;
}


/********** Media queries ************/

@media screen and (min-width: 992px) {
  .mainSection .dz-form.bg-primary.style-1.information {
    padding: 32px 32px 44px;
    max-height: 285px;
    top: 50%;
    transform: translate(0, -50%);
    opacity: 0.9;
  }

  .mainSection .title.info-title {
    font-size: 32px;
    padding-bottom: 25px;
    margin-bottom: 25px;
    border-bottom: 1px solid #ffffff;
  }

  .mainSection .mapP {
    font-size: 16px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .mainSection .dz-form.bg-primary.style-1.information {
    padding: 37px 44px;
    width: 100%;
    min-height: 257px;
    top: 0;
  }

  .mainSection .title.info-title {
    font-size: 36px;
    max-width: 350px;
    margin-bottom: 24px;
  }

  .mainSection .mapP {
    font-size: 15px;
  }
}
@media screen and (max-width: 767px) {
  .mainSection .dz-form.bg-primary.style-1.information {
    padding: 33px 28px 33px 26px;
    width: 100%;
    top: 0;
    min-height: 257px;
  }

  .mainSection .title.info-title {
    font-size: 32px;
    margin-bottom: 25px;
  }
}
