.infoCards .dz-title {
  color: #F16821;
}

.infoCards .icon-bx-wraper.style-6 {
  cursor: pointer;
}

.icon-bx-wraper.style-6.box-hover.active .onHover {
  filter: brightness(10) !important;
}

.icon-lg img {
  height: 87px;
}

.infoCardsWrapper .icon-bx-wraper.style-6.agricultureHover.active:after,
.icon-bx-wraper.style-6.agricultureHover:hover:after {
  background-image: url('../../../assets/images/HomePage/agriculture.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.infoCardsWrapper .icon-bx-wraper.style-6.whiteTruckHover.active:after,
.icon-bx-wraper.style-6.whiteTruckHover:hover:after {
  background-image: url('../../../assets/images/HomePage/whiteTruckHover.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.infoCardsWrapper .icon-bx-wraper.style-6.contactHover.active:after,
.icon-bx-wraper.style-6.contactHover:hover:after {
  background-image: url('../../../assets/images/HomePage/contactHover.png');
  background-repeat: no-repeat;
  background-size: cover;
}

/********** Media queries ************/

@media (min-width: 1025px) {
  .infoCards.col-lg-6.aos-item {
    margin: 6.5rem 9rem 6.5rem auto;
  }
}

@media (min-width: 1200px) {
  .infoCards .icon-bx-wraper.style-6 {
    height: 31rem !important;
  }

  .infoCardsWidth.col-lg-4.col-md-6.m-lg-b30.aos-item {
    min-width: 18%;
    max-width: 32rem;
  }

  .infoCards.row.align-items-center {
    justify-content: center !important;
  }
}

@media (min-width: 375px) and (max-width: 667px) {
  .infoCards.col-lg-6.aos-item {
    margin: 20px 0 30px 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 988px) {
  .infoCardsWrapper .infoCards {
    margin: 0 23px;
  }

  .infoCardsWrapper h5.sub-title {
    font-size: 18px;
    margin: 62px 0 61px;
  }

  .infoCardsWrapper .infoCardsWidth {
    margin-bottom: 40px;
    padding: 0 14px;
  }
}
