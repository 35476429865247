.bannerWithPhotosAndText .transform {
  transform: translate(0, -16px);
}

.bannerWithPhotosAndText .btn,
.bannerWithPhotosAndText .wp-block-button__link {
  padding: 15px;
}

.bannerWithPhotosAndText .buttonTextWineCellar {
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
}

.bannerWithPhotosAndText {
  position: relative;
  padding-top: 130px;
  padding-bottom: 172px;
  z-index: 0;
  overflow: hidden;
  margin: 51px 41px;
}

.bannerWithPhotosAndText .barrel {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  height: 100%;
}

.bannerWithPhotosAndText .grape {
  width: 100%;
  object-fit: contain;
  height: 100%;
  max-width: 1186px;
  position: absolute;
  left: 50%;
  z-index: -2;
  top: 0;
}

.bannerWithPhotosAndText .wineCellarSimpleText {
  max-width: 940px;
  width: 100%;
  margin: auto;
  color: #000;
}

/********** Media queries ************/

@media (min-width: 2001px) {
  .bannerWithPhotosAndText .grape {
    transform: translate(20rem, 0);
  }
}

@media (min-width: 1920px) and (max-width: 2000px) {
  .bannerWithPhotosAndText .grape {
    transform: translate(5rem, 0);
  }
}

@media (max-width: 988px) and (min-width: 1919px) {
  .bannerWithPhotosAndText .grape {
    transform: translate(8rem, 0);
  }
}

@media (max-width: 1440px) {
  .bannerWithPhotosAndText .grape {
    transform: translate(-9rem, 0);
  }
}

@media (min-width: 989px) {
  .bannerWithPhotosAndText .btn,
  .bannerWithPhotosAndText .wp-block-button__link {
    padding: 15px;
    transform: translate(0, 54px);
  }

  .bannerWithPhotosAndText .grapeBannerVertical {
    display: none;
  }

  .bannerWithPhotosAndText .grapeTablet {
    display: none;
  }
}

@media (max-width: 767px) {
  .bannerWithPhotosAndText .grape,
  .bannerWithPhotosAndText .barrel {
    display: none;
  }

  .bannerWithPhotosAndText .grapeTablet {
    display: none;
  }

  .bannerWithPhotosAndText .grapeBannerVertical {
    position: absolute;
    z-index: -1;
    height: 100%;
  }

  .bannerWithPhotosAndText {
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
  }

  .bannerWithPhotosAndText .wineCellarSimpleText {
    padding: 60px 22px 54px 23px;
  }

  .bannerWithPhotosAndText .btn,
  .bannerWithPhotosAndText .wp-block-button__link {
    padding: 15px;
    transform: translate(0, 30px);
  }
}

@media (min-width: 768px) and (max-width: 988px) {

  .bannerWithPhotosAndText .btn,
  .bannerWithPhotosAndText .wp-block-button__link {
    padding: 15px;
    transform: translate(0, 15px);
  }

  .bannerWithPhotosAndText .grapeBannerVertical {
    display: none;
  }

  .bannerWithPhotosAndText .grape {
    display: none;
  }

  .bannerWithPhotosAndText .barrel {
    display: none;
  }

  .bannerWithPhotosAndText .grapeTablet {
    position: absolute;
    z-index: -1;
    width: 100%;
    left: 4px;
    top: -30px;
  }

  .bannerWithPhotosAndText {
    margin: 0;
  }

  .bannerWithPhotosAndText .wineCellarSimpleText {
    padding: 44px 42px 80px 42px;
  }

  .bannerWithPhotosAndText {
    padding: 0;
  }
}
