.textBannerWithVideo .town {
  font-family: Poppins, sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: #000;
  margin-bottom: 0;
}

.textBannerWithVideo .townPhone {
  font-family: Poppins, sans-serif;
  font-weight: 300;
  font-size: 20px;
  color: #000;
}

.textBannerWithVideo .icon-bx-wraper i {
  width: 63px;
  height: 63px;
  margin-right: 13px;
  display: grid;
  place-content: center;
  border: 3px solid #f16821;
  color: #f16821;
  font-size: 32px;
}

.textBannerWithVideo .icon-bx-wraper {
  display: flex;
  align-items: center;
  margin-top: 36px;
}

.textBannerWithVideo .transform {
  transform: translate(0, -16px);
}

.textBannerWithVideo {
  position: relative;
  padding-top: 84px;
  padding-bottom: 120px;
  z-index: 0;
  overflow: hidden;
}

.textBannerWithVideo .wheyCut {
  position: absolute;
  bottom: 64px;
  left: -287px;
  z-index: -1;
  height: 106%;
}

.textBannerWithVideo .fields {
  width: 100%;
  object-fit: cover;
  height: 100%;
  max-width: 1186px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: -2;
  top: 0;
}

.textBannerWithVideo .simpleText {
  max-width: 940px;
  width: 100%;
  margin: auto;
  font-family: Raleway, sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #000;
}

.textBannerWithVideo .wheyVideo {
  position: absolute;
  bottom: 56%;
  transform: translateY(50%);
  height: 65rem;
  right: -125px;
  width: 74rem;
  z-index: -1;
  mix-blend-mode: multiply;
}

.textBannerWithVideo .simpleTextSingle {
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #000;
  margin-bottom: 0;
}

.textBannerWithVideo .margin {
  margin-top: 16px;
}

/********** Media queries ************/

@media (min-width: 1920px) and (max-width: 2000px) {
  .textBannerWithVideo .wheyVideo {
    transform: translate(1% ,50%);
  }
}

@media (max-width: 1440px) {
  .textBannerWithVideo .wheyVideo {
    transform: translate(12% ,51%);
    height: 65rem;
  }
}

@media (max-width: 767px) {
  .textBannerWithVideo .wheyCut,
  .textBannerWithVideo .fields,
  .textBannerWithVideo .wheyVideo {
    display: none;
  }

  .textBannerWithVideo .simpleTextSingle {
    font-weight: 300;
    font-size: 20px;
    color: #000;
  }

  .textBannerWithVideo .town {
    font-weight: 600;
    font-size: 15px;
    color: #000;
  }

  .textBannerWithVideo .townPhone {
    font-weight: 300;
    font-size: 15px;
  }

  .textBannerWithVideo .icon-bx-wraper i {
    width: 50px;
    height: 50px;
  }

  .textBannerWithVideo {
    padding-bottom: 50px;
  }

  .textBannerWithVideo .bannerMobile {
    position: absolute;
    z-index: -1;
    height: 100%;
  }

  .textBannerWithVideo .bannerTablet {
    display: none;
  }

  .textBannerWithVideo {
    padding-top: 0;
    padding-bottom: 0;
  }

  .textBannerWithVideo .simpleText {
    font-family: Raleway, sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: #000;
    padding: 40px 22px 30px 23px;
    height: 100%;
  }
}

@media (min-width: 989px) {
  .textBannerWithVideo .bannerMobile {
    display: none;
  }

  .textBannerWithVideo .bannerTablet {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 988px) {
  .textBannerWithVideo .fields {
    display: none;
  }

  .textBannerWithVideo .town {
    font-weight: 500;
    font-size: 20px;
    color: #000;
  }

  .textBannerWithVideo .simpleTextSingle {
    font-family: Poppins, sans-serif;
    font-weight: 300;
  }

  .textBannerWithVideo .wheyCut {
    display: none;
  }

  .textBannerWithVideo .bannerMobile {
    display: none;
  }

  .textBannerWithVideo .wheyVideo {
    display: none;
  }

  .textBannerWithVideo .bannerTablet {
    position: absolute;
    z-index: -1;
    bottom: 14%;
  }

  .textBannerWithVideo {
    padding: 0;
  }

  .textBannerWithVideo .simpleText {
    padding: 44px 42px;
  }
}
