.infoBoxV1 {
  background-color: transparent;
  background-image: linear-gradient(to left, #f16821, #f16a24, #f18221);
}

.infoBoxV1.dz-form.bg-primary.style-1 {
  color: white !important;
}

.infoBoxV1.dz-form.bg-primary.style-1 .mapV1title {
  font-size: 32px;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  border-bottom: 1px solid white;
  padding-bottom: 9px;
}

.mapV1Wrapper .layoutMapText.link {
  background-color: #fff;
  color: #f16821;
  font-weight: 500;
  font-family: Poppins, sans-serif;
  font-size: 15px;
}

.mapV1Wrapper .mapV1p {
  margin-block-start: 0;
  margin-block-end: 0;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.mapV1Wrapper .layoutMapText.link {
  background-color: transparent;
  border: 1px solid #fff;
  padding: 10px 16px 10px 16px;
}

.mapV1Wrapper .layoutMapText.link a {
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
}

.mapV1Wrapper .layoutMapText {
  display: flex;
  flex-direction: row;
}

.onMobile {
  display: none;
}

.mapV1Wrapper .titleDesktop {
  margin-bottom: 400px;
  font-size: 64px;
  color: #002755;
  margin-left: 40px;
}

.mapV1Wrapper.bg-light {
  background-color: #efefef !important;
}

/*!********** Media queries ************!*/

@media (min-width: 999px) {
  .mapV1Wrapper .dz-form.bg-primary.style-1.infoBoxV1 {
    transform: translate(-117px, -23.6rem);
    max-width: 38rem;
  }

  .mapV1Wrapper .dz-form.style-1 {
    padding: 14px 30px;
  }

  .infoBoxV1 .mapV1p {
    font-size: 16px;
    margin-left: 18px;
  }

  .mapV1Wrapper .layoutMapText {
    margin-bottom: 22px;
  }

  .mapV1Wrapper .layoutMapText.link a {
    margin-left: 11px;
  }

  .mapV1Wrapper.bg-light {
    margin-bottom: 60px;
  }
}

@media (max-width: 989px) {
  .onDesktop {
    display: none;
  }

  .onMobile {
    display: block;
  }
}

@media (min-width: 989px) {
  .mapV1Wrapper .text-primary.sub-title {
    font-family: Raleway, sans-serif;
    font-weight: 800;
    font-size: 18px;
    padding-top: 96px;
    color: #f16821;
    margin-left: 40px;
  }

  .mapV1Wrapper .titleDesktop {
    width: 38%;
  }
}

/*TABLET*/

@media (min-width: 768px) and (max-width: 988px) {
  .mapV1Wrapper .text-primary.sub-title {
    font-family: Raleway, sans-serif;
    font-weight: 800;
    font-size: 18px;
    padding-top: 29px;
    color: #f16821;
    margin-left: 26px;
  }

  .mapV1Wrapper .title.titleResponsive {
    font-family: Poppins, sans-serif;
    font-weight: 700;
    font-size: 36px;
    margin-left: 26px;
    color: #002755;
    width: 34%;
  }

  .infoBoxV1.dz-form.bg-primary.style-1 .mapV1title {
    border: none;
    font-size: 36px;
  }

  .infoBoxV1 .mapV1p {
    font-size: 15px;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    padding-left: 17px;
  }

  .mapV1Wrapper .layoutMapText.link {
    margin-top: 28px;
    padding: 10px 20px 10px 20px;
  }

  .mapV1Wrapper .layoutMapText.link a {
    padding-left: 11px;
  }
}

/*MOBILE*/

@media (max-width: 767px) {
  .mapV1Wrapper .text-primary.sub-title {
    font-family: Raleway, sans-serif;
    font-weight: 800;
    font-size: 16px;
    color: #f16821;
    padding-top: 83px;
    margin-left: 10px;
  }

  .mapV1Wrapper .titleResponsive {
    font-family: Raleway, sans-serif;
    font-weight: 800;
    font-size: 32px;
    color: #002755;
    margin-bottom: 77px;
    margin-left: 10px;
  }

  .infoBoxV1.dz-form.bg-primary.style-1 .mapV1title {
    border-bottom: none;
  }

  .infoBoxV1.dz-form.style-1 {
    padding: 44px 0 44px 15px;
  }

  .infoBoxV1 .layoutMapText {
    margin-bottom: 27px;
  }

  .infoBoxV1 .mapV1Icon {
    margin-right: 18px;
  }

  .infoBoxV1 .mapV1p {
    font-size: 14px;
  }
}
