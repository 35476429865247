.whiteInnerContent.silder-content {
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(1px);
}

.inner-content .subtitleSlider {
  color: #ffffff !important;
}

.whiteButton {
  background-color: transparent;
  border: 2px solid white;
  margin-right: 12px;
  font-weight: 600;
}

.whiteButton:hover,
.whiteButton:active,
.whiteButton:focus {
  background-color: #fff !important;
  border: 2px solid white !important;
  margin-right: 12px;
  color: #f16821 !important;
}

.sliderWrapper .silder-three .silder-content .title {
  font-family: Poppins, sans-serif;
}

.sliderWrapper .silder-three .overlay-black-middle:before {
  opacity: 0.5;
}

.sliderWrapper .orangeButton {
  border: 2px solid #f16821;
  font-weight: 600;
}

/********** Media queries ************/

@media (max-width: 374px) {
  .sliderWrapper .whiteButton {
    padding: 10px 16px;
    margin-right: 20px;
  }

  .sliderWrapper .orangeButton {
    padding: 10px 12px;
  }
}

@media (min-width: 375px) {
  .sliderWrapper .sub-title.text-primary.subtitleSlider {
    font-size: 14px;
    font-family: Raleway, sans-serif;
    font-weight: 500;
  }

  .silder-three .overlay-black-middle:before {
    opacity: 0.7;
  }

  .sliderWrapper .whiteButton {
    padding: 10px 16px;
    margin-right: 20px;
  }

  .sliderWrapper .orangeButton {
    padding: 10px 12px;
  }
}

@media (min-width: 992px) {
  .sliderWrapper .silder-three .silder-content {
    width: 684px;
  }

  .sliderWrapper .silder-three .silder-content .title {
    font-size: 56px;
    font-family: Poppins, sans-serif;
  }

  .sliderWrapper .sub-title.text-primary.subtitleSlider {
    font-size: 16px;
    font-family: Raleway, sans-serif;
    font-weight: 500;
    margin-bottom: 35px;
  }

  .sliderWrapper .btn.shadow-primary.btn-primary.whiteButton {
    padding: 10px 26px;
  }

  .sliderWrapper .btn.shadow-primary.btn-primary {
    padding: 10px 26px;
  }
}

@media (min-width: 375px) and (max-width: 667px) {
  .sliderWrapper .dz-slide-item.overlay-black-middle {
    height: 30%;
  }
}
