.bannerWithCenterText.dz-bnr-inr.dz-bnr-inr-sm h1 {
  font-family: Poppins, sans-serif;
  font-weight: 700;

  color: #fff;
}

.bannerWithCenterText.overlay-primary-middle:before,
.overlay-white-middle:before,
.overlay-gradient-middle:before,
.overlay-black-middle:before {
  opacity: 0.5;
}

/********** Media queries ************/

@media (min-width: 992px) {
  .bannerWithCenterText.dz-bnr-inr.dz-bnr-inr-sm {
    margin-top: 0;
  }

  .bannerWithCenterText.dz-bnr-inr.dz-bnr-inr-sm h1 {
    font-size: 72px;
  }

  .bannerWithCenterText.dz-bnr-inr.dz-bnr-inr-sm {
    height: 33rem;
  }

  .bannerWithCenterText.dz-bnr-inr h1,
  .bannerWithCenterText.dz-bnr-inr .h1 {
    margin-top: 9rem;
  }
}

@media (max-width: 767px) {
  .bannerWithCenterText.dz-bnr-inr.dz-bnr-inr-sm {
    height: 21.875rem;
    padding-top: 75px;
  }
}

@media (min-width: 768px) and (max-width: 988px) {
  .bannerWithCenterText.dz-bnr-inr.dz-bnr-inr-sm {
    height: 34.875rem;
  }

  .bannerWithCenterText .text-white {
    margin-bottom: 0;
    margin-top: 4rem;
  }
}
