.contactBanner .contactTitle {
  font-family: Poppins, sans-serif;
  font-weight: 700;
  font-size: 72px;
  color: #fff;
  margin-top: 9rem;
}

.contactForm .formTitle {
  font-family: Poppins, sans-serif;
  font-weight: 700;
  font-size: 64px;
  color: #002755;
  letter-spacing: 0.64px;
}

.contactForm .formSubtitle {
  font-family: Poppins, sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: #002755;
}

.contactForm .formText {
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #555;
  letter-spacing: 0;
}

.contactForm .btnContainer {
  background-color: #004f81;
  border-color: #004f81;
  padding: 15px 38px;
}

.contactForm .btnText {
  font-family: Poppins, sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #f7f7f7;
  letter-spacing: 0.14px;
}

.contactForm .btnContainer:hover {
  background-color: #002755;
}

@media screen and (min-width: 991px) {
  .contactBanner .dz-bnr-inr.dz-bnr-inr-md.backroungImage {
    height: 33rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .contactBanner .contactTitle {
    font-family: Poppins, sans-serif;
    font-weight: 700;
    font-size: 42px;
    margin-top: 4rem;
  }

  .contactBanner .dz-bnr-inr.dz-bnr-inr-md.backroungImage {
    height: 34.875rem;
  }
}

@media (max-width: 767px) {
  .contactBanner .contactTitle {
    font-family: Poppins, sans-serif;
    font-weight: 700;
    font-size: 36px;
    margin-top: 4rem;
  }

  .contactBanner .dz-bnr-inr.dz-bnr-inr-md.backroungImage {
    height: 21.875rem;
  }
}
