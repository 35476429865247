.sliderGallery.paddingTop {
  padding-top: 30px;
}

.sliderGallery .dz-gallery-box .product-info:hover {
  background-color: rgba(243, 130, 73, 0.69);
}

/********** Media queries ************/

@media (max-width: 767px) {
  .sliderGallery .project-nav .project-prev,
  .project-nav .project-next {
    height: 50px;
    width: 50px;
    line-height: 50px;
  }

  .sliderGallery .section-full.content-inner-1.bg-white {
    background-size: 250% !important;
  }
}

@media (min-width: 768px) and (max-width: 988px) {
  .sliderGallery .dz-media img {
    height: 100%;
    padding-left: 0;
  }

  .sliderGallery .section-full.content-inner-1.bg-white {
    background-size: 130% !important;
  }
}
