.textWithAnimation {
  overflow: hidden;
}
.textWithAnimation .about-bx5 .dz-media:after {
  height: 79%;
  content: '';
  background: #EFEFEF;
}

.width .dz-media img,
.dz-post-media img {
  width: 100%;
}

.textWithAnimation .about-bx5 .dz-media .circle-bg {
  left: 58rem;
  bottom: 19rem;
  opacity: 1.3;
  height: 461px;
  width: 461px;
}

.textWithAnimation .about-bx5 .dz-media .circle-bg {
  animation: flower 8s linear infinite;
  background-color: transparent;
}

.textWithAnimation .marginTop {
  margin-top: 17rem;
}

.textWithAnimation .list-square-check li {
  font-family: Raleway, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #000;
}

.textWithAnimation .title {
  font-family: Poppins, sans-serif;
  font-weight: 700 !important;
  font-size: 48px;
  color: #002755;
}

.textWithAnimation .section-paragraph {
  font-family: Raleway, sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #000;
}

.textWithAnimation .title-list {
  font-family: Poppins, sans-serif;
  font-weight: 700;
  color: #002755;
}

.textWithAnimation .list-square-check li:before,
.textWithAnimation .list-square-check li:hover:before {
  color: #ffffff;
  background-color: #f16821;
}

.textWithAnimation .list-square-check li {
  max-width: 400px;
  display: flex;
  align-items: center;
}
.textWithAnimation .list-square-check li:before {
  line-height: 54px;
  display: grid;
  place-content: center;
}

/********** Media queries ************/

@media (min-width: 1200px) {
  .textWithAnimation .list-square-check li {
    margin: 0 0 23px;
  }

  .textWithAnimation.content-inner-2 {
    padding-top: 60px;
  }
}

@media screen and (min-width: 767px) {
  .textWithAnimation .list-square-check li {
    height: 72px;
    padding-left: 93px;
  }
  .textWithAnimation .list-square-check li:before {
    width: 72px;
    height: 72px;
    font-size: 21px;
  }

  .textWithAnimation .title-list {
    font-size: 25px;
    margin-bottom: 22px;
  }
}
@media (min-width: 375px) and (max-width: 1024px) {
  .textWithAnimation .about-bx5 .dz-media .circle-bg {
    display: none;
  }
}

@media screen and (min-width: 767px) and (max-width: 992px) {
  .textWithAnimation .list-square-check li {
    margin: 0 0 16px;
    font-size: 15px;
  }

  .textWithAnimation .list-square-check li {
    margin: 0 0 16px;
  }
  .textWithAnimation .about-bx5 .dz-media {
    width: 100%;
  }

  .textWithAnimation .about-bx5 .dz-media:after {
    height: 30%;
    left: 0;
  }
  .width .dz-media img,
  .dz-post-media img {
    width: 60%;
  }
}

@media (max-width: 767px) {
  .textWithAnimation .title-list {
    margin-top: 46px;
  }

  .textWithAnimation .row > * {
    padding-left: 0;
    padding-right: 0;
  }

  .textWithAnimation .marginTop {
    margin-top: 3rem;
  }

  .textWithAnimation .title {
    text-align: center;
    font-size: 16px;
  }

  .textWithAnimation .width .dz-media img,
  .dz-post-media img {
    width: 80%;
    margin-left: 30px;
  }

  .textWithAnimation .about-bx5 .dz-media:after {
    width: 80%;
    height: 68%;
    left: -5%;
    bottom: -5%;
  }

  .textWithAnimation .title-list {
    font-size: 32px;
    margin-bottom: 31px;
  }

  .textWithAnimation .list-square-check li {
    height: 42px;
    padding: 0 0 0 69px;
    font-size: 16px;
  }

  .textWithAnimation .list-square-check li:first-of-type {
    margin-bottom: 31px;
  }

  .textWithAnimation .list-square-check li:last-of-type {
    margin-bottom: 75px;
  }
  .textWithAnimation .list-square-check li:before {
    width: 42px;
    height: 42px;
  }
}

@keyframes flower {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (min-width: 768px) and (max-width: 988px) {
  .textWithAnimation .width .dz-media img,
  .dz-post-media img {
    width: 90%;
    padding-left: 145px;
    padding-top: 70px;
  }

  .textWithAnimation .about-bx5 .dz-media:after {
    height: 66%;
    left: -55px;
    width: 83%;
  }

  .textWithAnimation .marginTop {
    margin-top: 6rem;
  }

  .textWithAnimation .section-head.style-3 h2,
  .textWithAnimation .section-head.style-3 .h2 {
    text-align: center;
  }

  .textWithAnimation .section-paragraph {
    font-size: 15px;
  }

  .textWithAnimation .title-list {
    margin-top: 43px;
    margin-bottom: 43px;
  }
}
