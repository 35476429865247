/* mob first*/

.bannerWithButton {
  margin-bottom: 3rem;
  background-image: linear-gradient(#f16821, #f16a24, #f18221) !important;
}

.bannerWithButton .mb-0 {
  font-family: Poppins, sans-serif;
  font-weight: 300;
  font-size: 15px;
  color: #fff;
  letter-spacing: 1.05px;
}

.bannerWithButton .title.text-white {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 32px;
}

.primaryButton {
  background-color: #fff;
  color: #f16821;
  border: none;
  font-weight: 600;
}

.primaryButton:hover {
  background-color: #123056;
  color: #fff;
  transition: width 2s, height 4s;
}
/********** Media queries ************/

@media screen and (min-width: 992px) {
  .bannerWithButton {
    margin-top: 105px;
    margin-bottom: 198px;
  }

  .bannerWithButton .text-center {
    text-align: start !important;
  }
}

@media screen and (max-width: 991px) {
  .bannerWithButton .col-lg-4.text-lg-end.text-center,
  .bannerWithButton .col-lg-8.text-white.text-lg-start.text-center {
    text-align: start !important;
  }

  .bannerWithButton title {
    font-size: 28px;
  }

  .bannerWithButton p {
    font-size: 15px;
    max-width: 491px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .section-full.call-action.bg-primary.wow.fadeIn.bannerWithButton {
    padding: 44px 10px;
    margin: 20px 0 60px;
  }

  .bannerWithButton .title.text-white {
    font-size: 28px;
  }
}

@media screen and (max-width: 667px) {
  .section-full.call-action.bg-primary.wow.fadeIn.bannerWithButton {
    padding: 38px 10px 27px;
    margin-bottom: 65px;
  }
  .bannerWithButton {
    background-image: linear-gradient(
      to left,
      #f16821,
      #f16a24,
      #f18221
    ) !important;
  }

  .bannerWithButton .title.text-white {
    font-size: 24px;
  }
}
