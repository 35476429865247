.masonryImages.content-inner {
  padding-top: 0;
  padding-bottom: 41px;
}

/********** Media queries ************/

@media (max-width: 767px) {
  .masonryImages.content-inner {
    padding-bottom: 18px;
  }

  .masonryImages .row {
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) / -2);
    margin-left: calc(var(--bs-gutter-x) / -2);
  }

  .masonryImages .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    padding-left: 12px;
    padding-right: 12px;
  }
}

@media (min-width: 768px) and (max-width: 988px) {

  .masonryImages .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    padding-left: 3px;
    padding-right: 3px;
  }
}
