.gpl-stations .dz-media {
  position: relative;
  overflow: unset;
  padding-top: 60px;
  padding-bottom: 60px;
  margin-top: 2rem;
}

.gpl-stations .dz-media .img1 {
  width: 250px;
  padding: 20px;
  margin-right: 0;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
}

.gpl-stations .dz-media img,
.dz-post-media img {
  width: 100%;
  height: auto;
}
.gpl-stations img {
  border-style: none;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

.gpl-stations .dz-media .img2 {
  margin-left: 200px;
  margin-top: 20px;
  position: relative;
  z-index: -1;
}

.gpl-stations .dz-media .img2:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  right: 60px;
  top: 60px;
  border: 5px solid #e4e4e4;
  z-index: -1;
}

.gpl-stations.contentInner-Statii {
  margin: 314px 0 120px;
  padding: 0;
}

.gpl-stations .gplStationsText p {
  text-align: left;
  letter-spacing: 0;
  width: 90%;
  color: #000;
  opacity: 1;
}

.gpl-stations .gplStationsText {
  margin-bottom: 60px;
}

.gpl-stations .sub-title {
  margin-bottom: 32px;
}

.gpl-stations .gplStationsListItems {
  width: 90%;
}

.gpl-stations .row {
  --bs-gutter-x: 100px;
}

.gpl-stations .title.GPL {
  font-family: Raleway, Bold, sans-serif;
  color: #000;
}

.gpl-stations .list-settings.gplStationsListItems li {
  padding: 0 0 0 30px;
  color: #000;
}

.gpl-stations .list-settings.gplStationsListItems li:before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #f16821;
  display: inline-block;
  top: 7px;
}

.gpl-stations .sub-title {
  font-size: 25px;
  color: #000;
}

.gpl-stations .col-lg-6.col-md-7.m-b30.aos-item.text-content {
  color: #000000;
}

.gpl-stations .about-media.style-2 img.imgGPL {
  border-radius: 3%;
}

.gpl-stations .gplStationsList {
  margin-bottom: 0;
  margin-top: 35px;
}

.gpl-stations .list-settings.gplStationsListItems {
  font-size: 18px;
  font-family: Raleway, sans-serif;
  font-weight: 500;
}

@media screen and (min-width: 991px) {
  .gpl-stations .title.GPL {
    font-size: 40px;
  }

  .gpl-stations .list-settings.gplStationsListItems li {
    margin-bottom: 24px;
  }
}

@media (max-width: 767px) {
  .gpl-stations .titleGplStations {
    color: #000;
  }

  .gpl-stations .list-settings.gplStationsListItems {
    font-size: 16px;
  }

  .gpl-stations.contentInner-Statii {
    margin: 314px 0 20px;
  }

  .gpl-stations .m-b30.gplStationsList {
    margin-bottom: 0;
  }

  .gpl-stations .gplStationsList {
    padding: 0;
    margin: 0;
  }

  .gpl-stations .gplStationsText {
    margin-bottom: 0;
    font-size: 16px;
  }

  .gpl-stations .row {
    --bs-gutter-x: 30px;
  }
  .gpl-stations .dz-media {
    position: relative;
    overflow: unset;
    padding-top: 60px;
    padding-bottom: 35px;
    margin-top: 6rem;
  }

  .gpl-stations .m-b30 {
    margin-bottom: 30px;
    padding-top: 30px;
  }

  .gpl-station .about-bx6 .dz-media .img1 {
    padding: 10px;
    margin-right: 0;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    background-color: #fff;
  }
  .gpl-stations .dz-media .img2 {
    margin-left: 100px;
    position: relative;
    z-index: -1;
  }

  .gpl-stations .dz-media img,
  .gpl-stations .dz-post-media img {
    width: 100%;
    height: auto;
  }

  .gpl-stations .dz-media .img2:after {
    content: '';
    position: absolute;
    width: 100%;
    right: 30px;
    top: 40px;
    height: 100%;
    border: 5px solid #e4e4e4;
    z-index: -1;
  }
}

@media (min-width: 768px) and (max-width: 988px) {
  .gpl-stations.contentInner-Statii {
    margin: 120px 0 120px;
  }

  .gpl-stations .titleGplStations {
    text-align: center;
    margin-top: 54px;
  }

  .gpl-stations .list-settings.gplStationsListItems li {
    font-size: 15px;
  }

  .gpl-stations .gplStationsListItems {
    width: 100%;
  }

  .gpl-stations .col-lg-6.col-md-12.m-b30.aos-item.text-content {
    padding-right: 0;
    padding-left: 50px;
  }

  .gpl-stations .list-settings.gplStationsListItems li {
    padding-bottom: 20px;
  }
}
